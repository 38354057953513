

import {mapState} from "vuex";
import moduleLayout from "@/layouts/moduleLayout";
import store from "@/store"
import db from "@/db"

export default {
  name: "mainLayout",
  components: {},
  mixins: [ moduleLayout ],
  data: () => ({
    company: null,
    users: null
  }),
  async created() {
    setTimeout(() => {
      this.setupIntercom()
      this.setupSTRUM()
    }, 1500);
  },
  methods: {
    setupSTRUM() {
      window['strum']('identify', {name: store.state.userProfile.email, identifier: store.state.userProfile.email})
      window['strum']('config', { token: '71393c7d-22fd-482b-860c-da193b021073', 'receiverUrl': 'https://rum-receiver.eu.sematext.com' });
    },
    async setupIntercom() {
      this.company = await this.getCompany()
      this.users = await this.getCompanyUsers()
      this.$intercom.shutdown();
      const intercom = {
        user_id: store.state.currentUser.ID,
        name: store.state.currentUser.name,
        email: store.state.userProfile.email,
        company: {
          company_id: store.state.tenantID,
          created_at: 1694531169,
          name: this.company.name,
          size: this.users.length
        }
      }
      this.$intercom.boot(intercom);
    },
    async getCompany() {
      return await db.collection('tenants/').doc(store.state.tenantID).get().then((doc) => {
        return doc.data()
      })
    },
    async getCompanyUsers() {
      return await db.collection(`tenants/${store.state.tenantID}/modules/users/records`).orderBy('ID').startAfter(0).get().then(sn => { return sn.docs.map(doc => doc.data()) })
    },
  },
  computed: {
    ...mapState(["tenantID", 'PORTAL', 'startPageAfterLogin'])
  },
  watch: {
    startPageAfterLogin() {
      if (this.PORTAL && this.startPageAfterLogin) {
        this.$router.push(this.startPageAfterLogin);
      }
    }
  }
};
